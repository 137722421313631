// In Next.js, the query params are typed as string | string[]
// This is a simple helper function to narrow the type to string
const dedupeQueryParameter = (queryParameter: string | string[]): string => {
  if (Array.isArray(queryParameter)) {
    return queryParameter[0];
  }

  return queryParameter;
};

export default dedupeQueryParameter;
