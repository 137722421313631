import Container from "@core/layout/Container";
import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth";
import cn from "classnames";

const LoginPrompt = ({ className }: { className?: string }) => {
  const { openModal } = useAuthenticationModal();
  return (
    <div className={cn("flex justify-center text-center px-8", className)}>
      <Container disablePadding>
        <p className="font-bold text-24px tablet:text-[2.5rem] leading-[111%]">
          Discover more from the NOCD Community
        </p>

        <p className="text-14px tablet:text-16px leading-tight mt-1 mb-5">
          Gain personalized guidance and read inspiring stories from top OCD
          experts and real people working to conquer OCD.
        </p>

        <Button
          className="font-bold"
          onClick={() =>
            openModal({
              redirectAfterAuthentication: false,
              variant: "community",
            })
          }
        >
          Create a free account
        </Button>
      </Container>
    </div>
  );
};
export default LoginPrompt;
