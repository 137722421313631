import { recordUnauthCommunityRegistrationClick } from "@core/services/gtm";
import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth";
import Image from "next/image";
import { FC, useCallback } from "react";

const JoinConversationModule: FC = () => {
  const { openModal } = useAuthenticationModal();

  const handleUnauthenticatedClick = useCallback(() => {
    try {
      recordUnauthCommunityRegistrationClick();
    } catch (gtmError) {
      console.error(
        "Error recording registration in GTM ",
        gtmError instanceof Error ? gtmError.message : ""
      );
    }
    openModal({
      redirectAfterAuthentication: false,
      variant: "community",
    });
  }, [openModal]);

  return (
    <div className="flex flex-col items-center relative rounded-2xl border border-gray-200 shadow overflow-hidden pt-8 pb-6 px-6">
      <Image
        layout="fill"
        objectFit="cover"
        objectPosition="top center"
        src="/images/community/background-gradients/mobile-auth.svg"
        alt=""
        aria-hidden="true"
        className="z-0"
      />

      <div className="relative">
        <h2 className="text-20px font-bold text-center">
          Join the conversation
        </h2>

        <p className="text-center mt-1 mb-3 leading-snug">
          Find inspiration, motivate others, and learn from OCD experts to start
          your recovery journey with NOCD.
        </p>

        <div className="flex justify-center">
          <Button
            className="font-bold rounded-lg"
            onClick={handleUnauthenticatedClick}
          >
            Create a free account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JoinConversationModule;
