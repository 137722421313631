import { useSession } from "@core/hooks/useSession";
import { recordUnauthCommunityRegistrationClick } from "@core/services/gtm";
import Avatar from "@core/ui/Avatar";
import { useAuthenticationModal } from "@features/auth";
import { ChatIcon, HeartIcon } from "@heroicons/react/solid";
import cn from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, MouseEventHandler, useCallback } from "react";
import toast from "react-hot-toast";

import { useMutatePostAction } from "../hooks/useMutatePostAction";
import { PostActionIds, PostExt } from "../types";
import { getUserAvatarUrl, getUsernameToDisplay } from "./PostCard";
import PostContent from "./PostContent";

interface MilestoneCardProps {
  data: PostExt;
  variant:
    | "sustained_conqueror_community_post"
    | "treatment_conqueror_community_post";
}

const getImageUrl = (variant: MilestoneCardProps["variant"]) => {
  switch (variant) {
    case "sustained_conqueror_community_post":
      return "/images/community/milestone-discover-images/sustained.png";
    case "treatment_conqueror_community_post":
      return "/images/community/milestone-discover-images/conqueror.png";
    default:
      return null;
  }
};

const MilestoneCard: FC<MilestoneCardProps> = ({ data, variant }) => {
  const { data: session } = useSession();
  const { mutateAsync: performPostAction } = useMutatePostAction(data.id);
  const { openModal } = useAuthenticationModal();

  const { push } = useRouter();

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      if (data.id != null && (event.target as HTMLElement).tagName !== "A") {
        void push(`/community/posts/${data.id}`);
      }
    },
    [data.id, push]
  );

  const handleUnauthenticatedClick = useCallback(() => {
    try {
      recordUnauthCommunityRegistrationClick();
    } catch (gtmError) {
      console.error(
        "Error recording registration in GTM ",
        gtmError instanceof Error ? gtmError.message : ""
      );
    }
    openModal({
      redirectAfterAuthentication: false,
      variant: "community",
    });
  }, [openModal]);

  return (
    <article
      role="presentation"
      onClick={handleClick}
      id={`${data.id}`}
      className="cursor-pointer relative z-0"
    >
      <div className="inline-block relative w-full h-58 tablet:h-62 bg-indigo-800">
        <Image
          src={getImageUrl(variant)}
          alt=""
          aria-hidden="true"
          layout="fill"
          objectFit="cover"
          objectPosition="center 75%"
          className="relative -z-1"
        />
      </div>

      <div className="py-8 tablet:pt-9 pb-6 px-4 space-y-4 tablet:px-6 text-14px">
        <div className="flex">
          <Avatar
            size="custom"
            src={getUserAvatarUrl(data)}
            alt={data.community_handle}
            className="rounded-full flex-shrink-0 mr-2 h-[54px] w-[54px] tablet:h-[58px] tablet:w-[58px]"
          />
          <div>
            <dl className="ml-2">
              <dt className="sr-only">Username</dt>
              <dd>{getUsernameToDisplay(data, "discover")}</dd>
            </dl>

            {data.badge?.background_color_hex &&
            data.badge?.image_url &&
            data.badge?.text &&
            data.badge?.text_color_hex ? (
              <span
                className="inline-flex rounded-full px-2 py-1 items-center"
                style={{
                  backgroundColor: `#${data.badge?.background_color_hex}`,
                  color: `#${data.badge?.text_color_hex}`,
                }}
              >
                <dl>
                  <dt className="sr-only">User type</dt>
                  <dd className="truncate">{data.badge?.text}</dd>
                </dl>
              </span>
            ) : null}

            <dl className="opacity-70 ml-2">
              <dt className="sr-only">Date posted</dt>
              <dd>{data.human_timestamp?.replace(" (Following)", "")}</dd>
            </dl>
          </div>
        </div>

        <PostContent hideImage variant="feed" post={data} />

        <div className="flex justify-start space-x-4 text-14px">
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              if (!session) {
                return handleUnauthenticatedClick();
              }

              return performPostAction({
                actionId: PostActionIds.LIKE_POST,
                postId: data.id,
                isEnabled: data.is_liked,
                userId: null,
              }).catch((error: Error) => toast.error(error.message));
            }}
            className="group flex items-center space-x-1 text-gray-500"
          >
            <HeartIcon
              className={cn(
                "h-4.5 w-4.5",
                data.is_liked
                  ? "fill-current stroke-current text-red-500 group-hover:text-red-600 group-focus:text-red-600"
                  : "stroke-current group-hover:text-gray-700 group-focus:text-gray-700"
              )}
            />
            <span
              className={cn(
                data.is_liked
                  ? "text-red-500 group-hover:text-red-600"
                  : "group-hover:text-gray-700"
              )}
            >
              {data.likes}
            </span>
          </button>

          <span
            role="presentation"
            // Prevent parent from firing click handler
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Link href={`/community/posts/${data.id}`}>
              <a className="flex items-center space-x-1 text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700">
                <ChatIcon className="h-4.5 w-4.5" />
                <span>{data.num_replies}</span>
              </a>
            </Link>
          </span>
        </div>
      </div>
    </article>
  );
};

export default MilestoneCard;
