import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import { DiscoverFeedPost } from "../types";

export const getPublicDiscoverFeedQueryKey = (topic?: string | null) =>
  ["community-discover-feed", topic] as const;

const usePublicDiscoverFeed = (
  topic?: string | null
): UseQueryResult<{ posts: DiscoverFeedPost[] }, Error> => {
  return useQuery(
    getPublicDiscoverFeedQueryKey(topic),
    ({ signal }) =>
      api
        .get<{ posts: DiscoverFeedPost[] }>("/v1/community/discover_feed", {
          signal,
          params: {
            topic,
          },
        })
        .then(({ data }) => data),
    {
      staleTime: Infinity,
    }
  );
};

export default usePublicDiscoverFeed;
