/* eslint-disable no-nested-ternary */
import { getQueryKey, useSession } from "@core/hooks/useSession";
import ErrorScreen from "@core/layout/ErrorScreen";
import dedupeQueryParameter from "@core/utils/dedupeQueryParameter";
import getServerSession from "@core/utils/getServerSession";
import { PostExt } from "@features/community-v2";
import { useContentTypeStore } from "@features/community-v2/components/ContentTypeDialog";
import JoinConversationModule from "@features/community-v2/components/JoinConversationModule";
import JourneyCard from "@features/community-v2/components/JourneyCard";
import LoginPrompt from "@features/community-v2/components/LoginPrompt";
import MilestoneCard from "@features/community-v2/components/MilestoneCard";
import PageWrapper from "@features/community-v2/components/PageWrapper";
import PostCard from "@features/community-v2/components/PostCard";
import PostFlag from "@features/community-v2/components/PostFlag";
import PostSkeleton from "@features/community-v2/components/PostSkeleton";
import usePrivateDiscoverFeed from "@features/community-v2/hooks/usePrivateDiscoverFeed";
import usePublicDiscoverFeed from "@features/community-v2/hooks/usePublicDiscoverFeed";
import cn from "classnames";
import { omit } from "lodash";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import { FC, useCallback, useMemo } from "react";
import Masonry from "react-masonry-css";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

const DiscoverPostFeed: FC = ({ children }) => {
  return (
    <>
      <Masonry
        breakpointCols={{ default: 3, 1023: 2, 768: 1 }}
        className="discover-masonry-grid mt-2 z-0 relative"
        columnClassName="discover-masonry-grid_column space-y-3"
      >
        {children}
      </Masonry>
    </>
  );
};

const PublicDiscoverFeed = () => {
  const router = useRouter();
  const contentTypeState = useContentTypeStore();
  const { data, isLoading, error } = usePublicDiscoverFeed(
    dedupeQueryParameter(router.query.topic)
  );

  const clearTopic = useCallback(() => {
    return router.push({ query: omit(router.query, "topic") }, undefined, {
      shallow: true,
    });
  }, [router]);

  const postsToShow = useMemo(
    () =>
      data?.posts.filter((post) => {
        if (post.type === "join_conversation_module") {
          return true;
        }
        if (post.type === "ocd_journey") {
          return contentTypeState.journeyStories;
        }
        if (
          post.type === "top_community_post" ||
          post.type === "community_post"
        ) {
          return contentTypeState.topPosts;
        }
        if (
          post.type === "sustained_conqueror_community_post" ||
          post.type === "treatment_conqueror_community_post"
        ) {
          return contentTypeState.memberAchievements;
        }
        return false;
      }),
    [data?.posts, contentTypeState]
  );

  return (
    <PageWrapper
      topic={dedupeQueryParameter(router?.query?.topic)}
      clearTopic={clearTopic}
      hideFilters
      hideSearch
      showContentTypeFilter
      hideSidebar
    >
      <DiscoverPostFeed>
        {isLoading ? (
          [
            // The masonry layout component doesn't work unless we pass an
            // array of children, so we have to do this weird thing
            "loader-1",
            "loader-2",
            "loader-3",
            "loader-4",
            "loader-5",
            "loader-6",
            "loader-7",
            "loader-8",
            "loader-9",
            "loader-10",
            "loader-11",
            "loader-12",
            "loader-13",
          ].map((item) => (
            <PostSkeleton key={`public-loading-skeleton-${item}`} />
          ))
        ) : error ? (
          <ErrorScreen error={error} />
        ) : (
          postsToShow.map((post) => {
            if (post.type === "join_conversation_module") {
              return <JoinConversationModule key={post.data.id} />;
            }

            if (post.type === "top_community_post") {
              return (
                <section
                  key={`top-community-post-${post.data.id}`}
                  className="border border-gray-200 pb-6 pt-7.5 relative px-4 bg-white tablet:mx-0 rounded-2xl tablet:px-5 shadow-sm"
                >
                  <PostFlag text="Top Posts" bgHex="#00a3ad" />

                  <PostCard
                    id={String(post.data.id)}
                    onClickReply={{
                      type: "link",
                      href: `/community/posts/${post.data.id}`,
                    }}
                    post={post.data}
                    rootPostId={post.data.id}
                    variant="discover"
                  />
                </section>
              );
            }

            if (post.type === "community_post") {
              return (
                <section
                  key={`generic-community-post-${post.data.id}`}
                  className="border border-gray-200 pb-6 pt-7.5 relative px-4 -mx-4 bg-white tablet:mx-0 rounded-2xl tablet:px-6 tablet:shadow-sm"
                >
                  <PostCard
                    id={String(post.data.id)}
                    onClickReply={{
                      type: "link",
                      href: `/community/posts/${post.data.id}`,
                    }}
                    post={post.data}
                    rootPostId={post.data.id}
                    variant="discover"
                  />
                </section>
              );
            }

            if (
              [
                "sustained_conqueror_community_post",
                "treatment_conqueror_community_post",
              ].includes(post.type)
            ) {
              return (
                <section
                  key={`community-milestone-post-${(post.data as PostExt).id}`}
                  className="relative border border-gray-200 bg-white tablet:mx-0 rounded-2xl shadow-sm overflow-hidden"
                >
                  <MilestoneCard
                    data={post.data as PostExt}
                    variant={
                      post.type as
                        | "sustained_conqueror_community_post"
                        | "treatment_conqueror_community_post"
                    }
                  />
                  <PostFlag
                    text="Member Achievements"
                    bgHex="#FFFFFF"
                    textColor="#6f76ef"
                  />
                </section>
              );
            }

            if (post.type === "ocd_journey") {
              return (
                <section
                  key={post.data.slug}
                  className="border border-gray-200 relative bg-white tablet:mx-0 rounded-2xl shadow-sm overflow-hidden"
                >
                  <JourneyCard
                    image={post.data.featuredImage.node.mediaItemUrl}
                    description={post.data.customFields.description}
                    authorName={post.data.customFields.authorName}
                    slug={post.data.slug}
                  />

                  <PostFlag bgHex="#6f76ef" text="OCD Journey Stories" />
                </section>
              );
            }
            return null;
          })
        )}
      </DiscoverPostFeed>

      {postsToShow?.length ? (
        <LoginPrompt
          className={cn(
            "bg-gradient-to-b from-transparent via-white to-white z-1",
            "absolute bottom-0 pb-16 pt-60",
            "w-[100vw] left-[50%] right-[50%] ml-[-50vw] mr-[-50vw]"
          )}
        />
      ) : null}
    </PageWrapper>
  );
};

const PrivateDiscoverFeed = () => {
  const router = useRouter();
  const contentTypeState = useContentTypeStore();
  const { data, isLoading, error } = usePrivateDiscoverFeed(
    dedupeQueryParameter(router.query.topic)
  );
  const clearTopic = useCallback(() => {
    return router.push({ query: omit(router.query, "topic") }, undefined, {
      shallow: true,
    });
  }, [router]);

  const postsToShow = useMemo(
    () =>
      data?.posts.filter((post) => {
        if (post.type === "ocd_journey") {
          return !!contentTypeState.journeyStories;
        }
        if (
          post.type === "top_community_post" ||
          post.type === "community_post"
        ) {
          return !!contentTypeState.topPosts;
        }
        if (
          post.type === "sustained_conqueror_community_post" ||
          post.type === "treatment_conqueror_community_post"
        ) {
          return !!contentTypeState.memberAchievements;
        }
        return false;
      }),
    [
      data?.posts,
      contentTypeState.journeyStories,
      contentTypeState.topPosts,
      contentTypeState.memberAchievements,
    ]
  );

  return (
    <PageWrapper
      topic={dedupeQueryParameter(router?.query?.topic)}
      clearTopic={clearTopic}
      hideFilters
      hideSearch
      showContentTypeFilter
      hideSidebar
    >
      <DiscoverPostFeed>
        {isLoading ? (
          [
            // The masonry layout component doesn't work unless we pass an
            // array of children, so we have to do this weird thing
            "loader-1",
            "loader-2",
            "loader-3",
            "loader-4",
            "loader-5",
            "loader-6",
            "loader-7",
            "loader-8",
            "loader-9",
            "loader-10",
            "loader-11",
            "loader-12",
            "loader-13",
          ].map((item) => (
            <PostSkeleton key={`public-loading-skeleton-${item}`} />
          ))
        ) : error ? (
          <ErrorScreen error={error} />
        ) : (
          postsToShow.map((post) => {
            if (post.type === "top_community_post") {
              return (
                <section
                  key={`top-community-post-${post.data.id}`}
                  className="border border-gray-200 pt-7.5 pb-6 relative bg-white tablet:mx-0 rounded-2xl px-5 shadow-sm"
                >
                  <PostFlag text="Top Posts" bgHex="#00a3ad" />

                  <PostCard
                    id={String(post.data.id)}
                    onClickReply={{
                      type: "link",
                      href: `/community/posts/${post.data.id}`,
                    }}
                    post={post.data}
                    rootPostId={post.data.id}
                    variant="discover"
                  />
                </section>
              );
            }

            if (post.type === "community_post") {
              return (
                <section
                  key={`generic-community-post-${post.data.id}`}
                  className="border border-gray-200 pb-6 pt-7.5 relative px-4 -mx-4 bg-white tablet:mx-0 rounded-2xl tablet:px-6 tablet:shadow-sm"
                >
                  <PostCard
                    id={String(post.data.id)}
                    onClickReply={{
                      type: "link",
                      href: `/community/posts/${post.data.id}`,
                    }}
                    post={post.data}
                    rootPostId={post.data.id}
                    variant="discover"
                  />
                </section>
              );
            }

            if (
              [
                "sustained_conqueror_community_post",
                "treatment_conqueror_community_post",
              ].includes(post.type)
            ) {
              return (
                <section
                  key={`community-milestone-post-${(post.data as PostExt).id}`}
                  className="relative border border-gray-200 bg-white tablet:mx-0 rounded-2xl shadow-sm overflow-hidden"
                >
                  <MilestoneCard
                    data={post.data as PostExt}
                    variant={
                      post.type as
                        | "sustained_conqueror_community_post"
                        | "treatment_conqueror_community_post"
                    }
                  />

                  <PostFlag
                    text="Member Achievements"
                    bgHex="#FFFFFF"
                    textColor="#6f76ef"
                  />
                </section>
              );
            }

            if (post.type === "ocd_journey") {
              return (
                <section
                  key={post.data.slug}
                  className="border border-gray-200 relative bg-white tablet:mx-0 rounded-2xl shadow-sm overflow-hidden"
                >
                  <JourneyCard
                    image={post.data.featuredImage.node.mediaItemUrl}
                    description={post.data.customFields.description}
                    authorName={post.data.customFields.authorName}
                    slug={post.data.slug}
                  />

                  <PostFlag bgHex="#6f76ef" text="OCD Journey Stories" />
                </section>
              );
            }
            return null;
          })
        )}
      </DiscoverPostFeed>
    </PageWrapper>
  );
};

const PAGE_TITLE =
  "OCD Community | Obsessive-Compulsive Disorder Forum and Discussion";
const PAGE_DESCRIPTION =
  "The NOCD Community provides support for people with OCD. You can meet others, share your experiences, and learn from others on a similar journey with OCD.";

export default function DiscoverFeed() {
  const { data: session } = useSession();

  return (
    <>
      <NextSeo
        title={PAGE_TITLE}
        description={PAGE_DESCRIPTION}
        openGraph={{
          description: PAGE_DESCRIPTION,
          title: PAGE_TITLE,
        }}
        canonical={`${process.env.NEXT_PUBLIC_CANONICAL_URL}/community`}
      />

      {session ? <PrivateDiscoverFeed /> : <PublicDiscoverFeed />}
    </>
  );
}

DiscoverFeed.backgroundColor = "gray-v2";

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const queryClient = new QueryClient();
  const session = await getServerSession(ctx);

  // Pre-load the session query
  queryClient.setQueryData(getQueryKey(), session);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};
